export default {
  woordhelder: {
    light: {
      anchor: '#1F9DE0',
      error: '#B40303',
      success: '#478500',
      info: {
        base: '#00334F',
        lighten1: '#005E92',
        lighten2: '#66C1B5',
        darken1: '#424265',
        darken2: '#83849D',
      },
      warning: {
        base: '#FF6600',
        lighten1: '#FF812D',
      },
      primary: {
        base: '#070A3C',
        lighten1: '#121655',
        lighten2: '#1F9DE0',
        lighten3: '#38A0D7',
        lighten4: '#4DB2E7',
        lighten5: '#82C5E4',
        lighten6: '#9CD7F2',
        lighten7: '#94E6FD',
        darken1: '#171717',
      },
      secondary: {
        base: '#808080',
        lighten1: '#DDE0E3',
        lighten2: '#F2F2F2',
        lighten3: '#FBFBFB',
        darken1: '#7E6B59',
      },
      accent: {
        base: '#FFC43B',
        darken1: '#E8AF2B',
      },
    },
  },
  zuidvallei: {
    light: {
      anchor: '#1F9DE0',
      error: '#B40303',
      success: '#478500',
      info: {
        base: '#00334F',
        lighten1: '#005E92',
        lighten2: '#66C1B5',
        darken1: '#424265',
        darken2: '#83849D',
      },
      warning: {
        base: '#FF6600',
        lighten1: '#FF812D',
      },
      primary: {
        base: '#171717',
        lighten1: '#121655',
        lighten2: '#E8AF2B',
        lighten3: '#38A0D7',
        lighten4: '#4DB2E7',
        lighten5: '#82C5E4',
        lighten6: '#9CD7F2',
        darken1: '#171717',
      },
      secondary: {
        base: '#808080',
        lighten1: '#DDE0E3',
        lighten2: '#F2F2F2',
        lighten3: '#FBFBFB',
        darken1: '#7E6B59',
      },
      accent: '#9CD7F2',
    },
  },
  textaid: {
    light: {
      anchor: '#FF6600',
      error: '#B40303',
      success: '#478500',
      info: {
        base: '#00334F',
        lighten1: '#005E92',
        lighten2: '#66C1B5',
        darken1: '#424265',
        darken2: '#83849D',
      },
      warning: {
        base: '#FF6600',
        lighten1: '#FF812D',
      },
      primary: {
        base: '#171717',
        lighten1: '#121655',
        lighten2: '#424265',
        lighten3: '#38A0D7',
        lighten4: '#4DB2E7',
        lighten5: '#82C5E4',
        lighten6: '#FF6600',
        darken1: '#171717',
      },
      secondary: {
        base: '#808080',
        lighten1: '#DDE0E3',
        lighten2: '#F2F2F2',
        lighten3: '#FBFBFB',
        darken1: '#7E6B59',
      },
      accent: '#FF6600',
    },
  },
  claroread: {
    light: {
      anchor: '#309ec0',
      error: '#B40303',
      success: '#478500',
      info: {
        base: '#00334F',
        lighten1: '#005E92',
        lighten2: '#66C1B5',
        darken1: '#424265',
        darken2: '#83849D',
      },
      warning: {
        base: '#FF6600',
        lighten1: '#FF812D',
      },
      primary: {
        base: '#222d43',
        lighten1: '#121655',
        lighten2: '#424265',
        lighten3: '#38A0D7',
        lighten4: '#4DB2E7',
        lighten5: '#82C5E4',
        lighten6: '#309ec0',
        darken1: '#103359',
      },
      secondary: {
        base: '#808080',
        lighten1: '#DDE0E3',
        lighten2: '#F2F2F2',
        lighten3: '#FBFBFB',
        darken1: '#7E6B59',
      },
      accent: '#309ec0',
    },
  },
};
