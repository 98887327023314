<template>
  <div>
    <h4 class="title white--text text-uppercase pt-sm-6 mb-3">
      Meld u aan voor<br class="d-none d-sm-block">
      <span class="primary--text text--lighten-6">onze nieuwsbrief</span>
    </h4>
    <form method="post" @submit.prevent="submit"/>
    <v-text-field
      v-model="email"
      dark
      outlined
      rounded
      color="accent"
      type="email"
      label="E-mailadres"
      class="sign-up"
    >
      <template v-slot:append>
        <v-btn color="accent" fab @click="submit">
          <font-awesome-icon :icon="['fab', 'telegram-plane']"/>
        </v-btn>
      </template>
    </v-text-field>
    <form/>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: '',
  }),
  methods: {
    async submit() {
      if (!this.email) {
        return;
      }

      try {
        await this.$axios.post('newsletter/guest', {email: this.email});
        this.$store.commit('notify/success', `${this.email} is succesvol aangemeld voor de nieuwsbrief.`);
        this.email = '';
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  padding: 0 0 0 24px !important;
}
</style>
