<template>
  <section class="footer primary white--text">
    <v-container class="px-7 px-sm-3">
      <v-row align="center" class="py-8 flex-column flex-sm-row">
        <v-col>
          <h4 class="title white--text text-uppercase mb-4">
            Volg ons op<br class="d-none d-sm-block">
            <span class="primary--text text--lighten-6">Sociale media</span>
          </h4>
          <div v-for="social in socials" :key="social.title" class="d-inline">
            <v-btn
              v-if="social.link"
              fab
              color="accent"
              class="ml-1 mr-4 mr-sm-6"
              :aria-label="social.title"
              :href="social.link"
              target="_blank"
              rel="noopener nofollow"
            >
              <font-awesome-icon :icon="social.icon"/>
            </v-btn>
          </div>
        </v-col>

        <v-col v-if="footerMenu && footerMenu.length">
          <h4 class="title white--text text-uppercase pb-2">
            Klantenservice<br class="d-none d-sm-block">
            <span class="primary--text text--lighten-6">& Privacy</span>
          </h4>
          <v-list v-for="link in footerMenu" :key="link.text" color="primary" class="pa-0" dense dark>
            <v-hover v-slot:default="{hover}">
              <v-list-item
                v-if="isExternal(link.url)"
                :href="link.url"
                :target="link.target ? link.target : ''"
                :class="[{'o-6': !hover}, {'white--text': $theme.is('zuidvallei')}]"
                :ripple="false"
                class="footer__nav pa-0"
              >
                {{ link.text }}
              </v-list-item>
              <v-list-item
                v-else
                :to="link.url"
                :target="link.target ? link.target : ''"
                :class="[{'o-6': !hover}, {'white--text': $theme.is('zuidvallei')}]"
                :ripple="false"
                class="footer__nav pa-0"
              >
                {{ link.text }}
              </v-list-item>
            </v-hover>
          </v-list>
        </v-col>

        <v-col v-if="faqsMenu">
          <h4 class="title white--text text-uppercase pb-2">
            Over<br class="d-none d-sm-block">
            <span class="primary--text text--lighten-6">ons</span>
          </h4>
          <v-list v-for="link in faqsMenu" :key="link.url" color="primary" class="pa-0" dense dark>
            <v-hover v-slot:default="{hover}">
              <v-list-item
                v-if="isExternal(link.url)"
                :href="link.url"
                :target="link.target ? link.target : ''"
                :class="{'o-6': !hover}"
                :ripple="false"
                class="footer__nav pa-0"
              >
                {{ link.text }}
              </v-list-item>
              <v-list-item
                v-else
                :to="link.url"
                :target="link.target ? link.target : ''"
                :class="{'o-6': !hover}"
                :ripple="false"
                class="footer__nav pa-0"
              >
                {{ link.text }}
              </v-list-item>
            </v-hover>
          </v-list>
        </v-col>

        <v-col>
          <mail-sign-up/>
        </v-col>
      </v-row>
      <v-row v-if="!$theme.isDefault">
        <v-col class="text-center pt-0">
          {{ $theme.label }} wordt uitgegeven door:
          <a href="https://woordhelder.nl" target="_blank" rel="noopener" class="no-line white--text font-weight-bold">Woordhelder B.V.</a>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import MailSignUp from '~/components/MailSignUp';

export default {
  components: {
    MailSignUp,
  },
  computed: {
    ...mapState({
      footerMenu: state => state.footerMenu,
      faqsMenu: state => state.faqsMenu,
      settings: state => state.settings,
    }),
    socials() {
      return [
        {
          title: 'facebook',
          link: this.settings.facebook,
          icon: ['fab', 'facebook-f'],
        },
        {
          title: 'twitter',
          link: this.settings.twitter,
          icon: ['fab', 'twitter'],
        },
        {
          title: 'youtube',
          link: this.settings.youtube,
          icon: ['fab', 'youtube'],
        },
        {
          title: 'instagram',
          link: this.settings.instagram,
          icon: ['fab', 'instagram'],
        },
      ];
    },
  },
  methods: {
    isExternal(url) {
      return /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.exec(url) !== null;
    },
  },
};
</script>
