<template>
  <div v-if="zendesk" v-html="zendesk"/>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      zendesk: state => state.settings.zendesk,
    }),
  },
};
</script>
