import isHTTPS from 'is-https';

export const state = () => ({
  release: process.env.RELEASE,
  host: '',
  page: {meta: {}},
  footerMenu: [],
  mainMenu: [],
  faqsMenu: [],
  settings: {},
  countries: {},
  countryOptions: [],
  recentArticles: [],
});

export const getters = {
  theme: state => state.host.split('.')[0],
};

export const actions = {
  async nuxtServerInit({commit}, {req}) {
    const host = req.headers.host;
    const referrer = (isHTTPS ? 'https://' : 'http://') + host + req.url;

    this.$axios.setHeader('referer', referrer);

    const [
      menus,
      settings,
      countries,
      recentArticles,
      productSite,
    ] = await Promise.all([
      this.$axios.get('cached/menu/combined'),
      this.$axios.get('cached/settings'),
      this.$axios.get('cached/countries'),
      this.$axios.get('articles', {params: {limit: 4}}),
      this.$axios.get('productsite'),
    ]);

    commit('SET_HOST', host);
    commit('SET_MAIN_MENU', menus.data.main);
    commit('SET_FOOTER_MENU', menus.data.footer);
    commit('SET_FAQS_MENU', menus.data.faqs);
    commit('SET_SETTINGS', settings.data);
    commit('SET_COUNTRIES', countries.data.data);
    commit('SET_RECENT_ARTICLES', recentArticles.data.data);
    commit('productSite/SET_PRODUCT_SITE', productSite.data.data);
  },

  async getPage({commit}, slug = '') {
    let page = {data: {data: {title: 'Error', meta: {title: 'Error'}}}};

    try {
      page = await this.$axios.get(`/page?segments=${slug}`);
    } catch (e) {
      if (e.response?.status === 404 || e.response?.status === 410) {
        this.$nuxt.error({statusCode: 404, message: e.message});
      }

      console.error(e);
    }

    commit('SET_PAGE', page.data.data);
  },
};

export const mutations = {
  SET_HOST(state, host) {
    state.host = host;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MAIN_MENU(state, payload) {
    state.mainMenu = payload;
  },
  SET_FAQS_MENU(state, payload) {
    state.faqsMenu = payload;
  },
  SET_FOOTER_MENU(state, payload) {
    state.footerMenu = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_COUNTRIES(state, payload) {
    const countries = {};

    payload.forEach((country) => {
      countries[country.title] = country;
    });

    state.countries = countries;
    state.countryOptions = Object.keys(countries);
  },
  SET_RECENT_ARTICLES(state, payload) {
    state.recentArticles = payload;
  },
};
