export default {
  methods: {
    async logOutUser() {
      try {
        await this.$auth.logout();
        await this.$router.go('/inloggen');
      } catch (e) {
        console.error(e);
      }
    },
    async logOutAdmin() {
      try {
        await this.$auth.logout();
        await this.$router.go('/admin');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
