export const state = () => ({
  activeSubject: {},
  items: [],
});

export const actions = {
  async getActiveSubject({commit}, payload) {
    let activeSubject = {};

    try {
      activeSubject = await this.$axios.get(`/subjects/${payload}`);
    } catch (e) {
      console.error(e);
    }

    if (activeSubject.data) {
      commit('SET_ACTIVE_SUBJECT', activeSubject.data.data);
      commit('SET_ITEMS', activeSubject.data.data.items);
    }
  },
};

export const mutations = {
  SET_ACTIVE_SUBJECT(state, payload) {
    state.activeSubject = payload;
  },
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
};
