<template>
  <v-container>
    <v-row>
      <v-col v-if="!hideImage" cols="2">
        <v-img v-if="product.image" :src="product.image"/>
      </v-col>
      <v-col :cols="hideImage ? 12 : 10" class="py-0">
        <v-row justify="space-between" class="px-3">
          <nuxt-link
            :to="{name: 'shop-category-product', params: {category: product.category, product: product.permalink}, query: {variant: product.variant}}"
            class="title primary--text"
            style="text-decoration: none;"
          >
            {{ product.title }}
          </nuxt-link>
          <div class="subtitle-1">€{{ (product.price_incl * product.quantity) | currency }}</div>
        </v-row>
        <v-row v-if="product.is_subscription" justify="space-between" class="px-3 pb-1">
          <div class="subtitle-2 font-weight-bold">Jaarabonnement</div>
        </v-row>
        <v-row v-if="product.monthly" justify="space-between" class="px-3">
          <div class="subtitle-2 font-weight-bold">Maandelijkse betaling</div>
          <div class="subtitle-2 font-weight-bold">€{{ product.monthly_incl | currency }}</div>
        </v-row>
        <div v-if="product.variant_title" class="subtitle-2 mt-n1 pb-2">
          Variant: {{ product.variant_title }}
        </div>
        <div v-if="product.options.length > 0" class="subtitle-2 font-weight-bold">
          <span>Extra opties:</span>
          <v-row
            v-for="option in product.options"
            :key="option.title"
            justify="space-between"
            class="flex-nowrap font-weight-regular pb-2"
            no-gutters
          >
            <v-col cols="10">{{ option.title }}</v-col>
            <v-col cols="auto">€{{ option.price_incl | currency }}</v-col>
          </v-row>
        </div>
        <div v-if="product.training" class="subtitle-2 font-weight-bold py-2">
          <span>{{ product.training.title }}:</span>
          <v-row
            justify="space-between"
            class="font-weight-regular px-3"
          >
            <div>{{ $moment(product.training.date).format('ll') }}</div>
            <div>€{{ product.training.price_incl | currency }}</div>
          </v-row>
        </div>
        <div class="subtitle-2 font-weight-bold">Aantal: {{ product.quantity }}</div>
        <div v-if="product.quantity > 1" class="subtitle-2 font-weight-bold">Prijs: €{{ product.price_incl | currency }}</div>
        <div class="subtitle-2 font-weight-bold">Btw-tarief: {{ product.tax_group }}%</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="d-flex justify-end">
        <v-btn
          class="cart subtitle-2 primary--text text--lighten-2 font-weight-bold mr-n3"
          text
          height="24"
          @click.stop="removeFromCart"
        >
          Verwijderen
        </v-btn>
      </v-col>
    </v-row>
    <hr>
  </v-container>
</template>

<script>
import ECommerceGtm from '~/plugins/mixins/e-commerce-gtm';

export default {
  mixins: [ECommerceGtm],
  props: {
    product: {
      type: Object,
      required: true,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeFromCart() {
      if (!window.confirm(`U staat op het punt om ${this.product.title} uit uw winkelwagen te verwijderen, klopt dit?`)) {
        return false;
      }

      this.gtmPushRemoveFromCart(this.product);
      this.$store.commit('cart/REMOVE_PRODUCT', this.product);
    },
  },
};
</script>
