<template>
  <v-container>
    <v-row tag="form" method="post" class="mb-12 flex-column flex-md-row" @submit.prevent="submit">
      <v-col cols="12" :md="split ? 4 : 6">
        <div v-for="field in split ? splitFields[0] : template.fields" :key="field.name">
          <v-select
            v-if="field.type === 'select'"
            v-model="form[field.name]"
            :items="field.options"
            :label="label(field)"
            :error-messages="errors[field.name]"
          />
          <v-text-field
            v-if="field.type === 'text' || field.type === 'email'"
            v-model="form[field.name]"
            :error-messages="errors[field.name]"
            :type="field.type"
            :label="label(field)"
            color="primary"
          />
          <v-textarea
            v-if="field.type === 'textarea'"
            v-model="form[field.name]"
            :error-messages="errors[field.name]"
            :type="field.type"
            :label="label(field)"
            rows="9"
            color="primary"
            outlined
          />
        </div>
        <v-btn
          v-if="!split"
          color="accent"
          :loading="loading"
          rounded
          nuxt
          class="d-flex ml-auto"
          @click="submit"
        >
          Verstuur
        </v-btn>
      </v-col>
      <v-col cols="12" md="7" class="pl-md-12">
        <div v-for="field in split ? splitFields[1] : template.fields" :key="field.name">
          <v-radio-group v-if="split && field.type === 'select'" v-model="form[field.name]" :mandatory="true" class="check caption">
            <v-radio v-for="option in field.options" :key="option" :color="$vuetify.theme.themes.light.anchor" :label="option" :value="option"/>
          </v-radio-group>
          <v-text-field
            v-if="split && (field.type === 'text' || field.type === 'email')"
            v-model="form[field.name]"
            :error-messages="errors[field.name]"
            :type="field.type"
            :label="label(field)"
            color="primary"
          />
          <v-textarea
            v-if="split && field.type === 'textarea'"
            v-model="form[field.name]"
            :error-messages="errors[field.name]"
            :type="field.type"
            :label="label(field)"
            rows="9"
            color="primary"
            outlined
          />
        </div>
        <v-btn
          v-if="split"
          color="accent"
          :loading="loading"
          rounded
          nuxt
          class="d-flex ml-auto"
          @click="submit"
        >
          Verstuur
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import commonForm from '~/plugins/mixins/form';

export default {
  mixins: [commonForm],
  props: {
    id: {
      type: Number,
      default: 1,
    },
    html: {
      type: String,
      default: '',
    },
    split: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      template: {},
      form: {},
      loading: false,
    };
  },
  computed: {
    splitFields() {
      if (!this.template.fields) {
        return [];
      }

      const splitIndex = Math.ceil(this.template.fields.length / 2);
      return [this.template.fields.slice(0, splitIndex), this.template.fields.slice(splitIndex)];
    },
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;

    // Set defaults
    const that = this;
    this.template.fields.forEach((field) => {
      if (field.type === 'checkbox') {
        // Default must be array to work
        that.form[field.name] = [];
      }
    });

    // Set product from url for demo form
    if (this.$route.query.product) {
      this.form.product_1 = this.$route.query.product;
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$axios.post('/forms/' + this.id, {...this.form});
        this.$store.commit('notify/success', `Uw bericht is verstuurd, wij zullen spoedig contact opnemen.`);
        this.form = {};
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
      this.success = false;
    },
    label(field) {
      if (field.required) {
        return `${field.label}*`;
      }

      return field.label;
    },
  },
};
</script>
