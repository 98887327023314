<template>
  <v-app>
    <cart-drawer/>
    <drawer/>
    <snackbar/>
    <read-btn :key="$route.path"/>
    <confirm-dialog ref="confirm"/>
    <v-main class="top">
      <nuxt id="read"/>
    </v-main>
    <page-footer/>
    <zendesk/>
  </v-app>
</template>

<script>
import Drawer from './partials/Drawer';
import CartDrawer from './partials/CartDrawer';
import Snackbar from './partials/Snackbar';
import PageFooter from './partials/PageFooter';
import ReadBtn from '~/layouts/partials/ReadBtn';
import ConfirmDialog from '~/components/dialogs/ConfirmDialog';
import Zendesk from '~/layouts/partials/Zendesk';
import dynamicFavicon from '~/plugins/mixins/dynamic-favicon';

export default {
  components: {
    Snackbar,
    Drawer,
    PageFooter,
    ReadBtn,
    CartDrawer,
    ConfirmDialog,
    Zendesk,
  },
  mixins: [dynamicFavicon],
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>
