export const state = () => ({
  items: [],
  page: 1,
  perPage: null,
  total: null,
  stats: {
    total: null,
    count: null,
    mostSold: [],
    outstanding: null,
  },
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PER_PAGE(state, limit) {
    state.perPage = limit;
  },
  SET_STATS(state, payload) {
    state.stats.total = payload.orders.sum_totaal;
    state.stats.count = payload.orders.count;
    state.stats.mostSold = payload.products.map(product => product.name);
    if (payload.outstanding) {
      state.stats.outstanding = payload.outstanding;
    }
  },
};

export const actions = {
  async getPage({state, commit}, options = {}) {
    options = {
      page: state.page,
      ...options,
    };

    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/admin/facturen?${params}`;

    try {
      const {data} = await this.$axios.get(url);

      commit('SET_ITEMS', data.data);
      commit('SET_TOTAL', parseInt(data.meta.total, 10));
      commit('SET_PER_PAGE', parseInt(data.meta.per_page, 10));
    } catch (e) {
      console.error(e);
    }
  },

  async getStats({commit}, options = {}) {
    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/admin/facturen/stats?${params}`;

    try {
      const {data} = await this.$axios.get(url);
      commit('SET_STATS', data);
    } catch (e) {
      console.error(e);
    }
  },
};
