export default {
  head() {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          href: require('~/assets/img/favicons/' + (this.$theme.current || 'woordhelder') + '.png'),
        },
      ],
    };
  },
};
