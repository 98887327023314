const themes = {
  'textaid-dyslexiesoftware.nl': 'textaid',
  'textaid.woordhelder.nl': 'textaid',

  'zuid-vallei.nl': 'zuidvallei',
  'zuidvallei.woordhelder.nl': 'zuidvallei',

  'claroread.woordhelder.nl': 'claroread',
  'claroread.nu': 'claroread',
};

const labels = {
  woordhelder: 'Woordhelder',
  textaid: 'Textaid',
  zuidvallei: 'Zuid-Vallei',
  claroread: 'ClaroRead',
};

const defaultTheme = 'woordhelder';

export const findThemeByDomain = (domain) => {
  return themes[domain] || defaultTheme;
};

export default ({req}, inject) => {
  let current = defaultTheme;

  if (process.server) {
    current = findThemeByDomain(process.host || req.headers.host);
  } else {
    current = findThemeByDomain(window.location.hostname);
  }

  inject('theme', new function () {
    this.current = current;
    this.label = labels[current] || 'Woordhelder';

    this.isDefault = current === defaultTheme;

    this.is = (theme) => {
      return this.current === theme;
    };
  }());
};
