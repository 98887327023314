import nl from 'vuetify/es5/locale/nl';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import themes from '~/assets/themes.js';
import {findThemeByDomain} from '~/plugins/theme';

const themeName = findThemeByDomain(process.server ? process.host : window.location.host);

export default {
  theme: {
    options: {
      customProperties: true,
    },
    themes: themes[themeName],
  },
  icons: {
    iconfont: 'faSvg',
    values: {
      dropdown: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'caret-down'],
        },
      },
      sort: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'sort-down'],
        },
      },
    },
  },
  lang: {
    locales: {nl},
    current: 'nl',
  },
};
