// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/border-heading.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/img/border-heading-wit.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "div[data-v-4c1e357b]:not(.white-border):not(.no-border){border-bottom:7px solid;border-top:0;-o-border-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 6;border-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 6;border-image-repeat:repeat}.white-border>h2[data-v-4c1e357b]{border-bottom:7px solid;border-top:0;-o-border-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 6;border-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 6;border-image-repeat:repeat}", ""]);
// Exports
module.exports = exports;
