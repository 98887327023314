<template>
  <v-app>
    <v-img :src="require('~/assets/img/bg-error.svg')" alt="Error" class="fill-height" cover>
      <v-container class="fill-height">
        <v-row class="fill-height" align="center">
          <v-col
            cols="10"
            sm="5"
            md="4"
            xl="3"
            offset="2"
            offset-sm="4"
            offset-cl="5"
          >
            <template v-if="error.statusCode === 410 || error.statusCode === 404">
              <border-header>
                Error error
              </border-header>
              <!--eslint-disable-next-line-->
              <div v-html="page.tekst" class="primary--text pb-6"/>
              <v-btn rounded color="accent" to="/" class="ml-6" nuxt>
                Terug naar home
              </v-btn>
            </template>
            <h1 v-else>
              Er is een fout opgetreden
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-app>
</template>

<script>
import {mapState} from 'vuex';
import BorderHeader from '~/components/header/BorderHeader';
import dynamicFavicon from '~/plugins/mixins/dynamic-favicon';

export default {
  components: {
    BorderHeader,
  },
  mixins: [dynamicFavicon],
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>
