import {ROLE_BEHEERDER, ROLE_DEVELOPER} from '~/plugins/constants';

export default {
  computed: {
    isAdmin() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.role_id === ROLE_BEHEERDER || this.$store.state.auth.user.role_id === ROLE_DEVELOPER;
      }
      return false;
    },
  },
};
