<template>
  <div>
    <form @submit.prevent="submit">
      <transition name="fade">
        <v-text-field
          v-if="open"
          v-model="q"
          :autofocus="open"
          solo
          light
          rounded
          background-color="accent"
          color="primary"
          class="search--field primary--text"
        >
          <template v-slot:append>
            <v-btn fab nuxt color="accent" class="btn-size mr-1 no-line" @click="open = !open">
              <font-awesome-icon :icon="['far', 'search']"/>
            </v-btn>
          </template>
          <template v-slot:label>
            <span class="caption primary--text font-italic">Zoek op site...</span>
          </template>
        </v-text-field>
      </transition>
    </form>
    <v-btn fab nuxt color="accent" class="d-none d-md-inline-flex no-line mr-5" @click="open = !open">
      <font-awesome-icon :icon="['far', 'search']"/>
    </v-btn>
    <v-btn fab large nuxt color="accent" class="d-md-none no-line mr-5 mr-sm-0" @click="open = !open">
      <font-awesome-icon :icon="['far', 'search']" size="lg"/>
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    open: false,
    q: '',
  }),
  watch: {
    $route: {
      handler() {
        this.open = false;
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.$router.push({path: '/zoekresultaten', query: {q: this.q}});
      this.open = false;
      this.q = '';
    },
  },
};
</script>

<style scoped lang="sass">
.search--field
  position: absolute
  top: 21px
  right: 30px
  width: 85vw
  max-width: 85vw
  z-index: 2
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    top: 36px
    width: 92vw
    max-width: 92vw
  @media screen and (min-width: 1025px)
    top: -4px
    right: 81.5%
    width: 54vw
    max-width: 54vw
  @media screen and (min-width: 1340px)
    width: 50vw
    max-width: 50vw
  @media screen and (min-width: 1430px)
    width: 47vw
    max-width: 47vw
  @media screen and (min-width: 1500px)
    width: 45vw
    max-width: 45vw
  @media screen and (min-width: map-get($grid-breakpoints, 'xl'))
    width: 55vw
    max-width: 55vw

  ::v-deep .v-input__slot
    min-height: 56px
    padding: 0 0 0 24px !important
    @media screen and (min-width: map-get($grid-breakpoints, 'md'))
      min-height: inherit

  .btn-size
    height: 50px
    width: 50px
    @media screen and (min-width: map-get($grid-breakpoints, 'md'))
      height: 41px
      width: 41px

.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0

</style>
