<template>
  <v-app-bar
    flat
    app
    dark
    color="primary"
    height="100"
  >
    <v-container :class="{'justify-space-between': !isAdmin}" class="px-1 d-flex align-center justify-space-between">
      <logo link="/admin"/>
      <v-toolbar-items v-if="isAdmin" class="d-none d-md-flex flex-grow-1 px-6">
        <template v-for="item in menu">
          <v-btn
            :key="item.text"
            :to="item.url"
            nuxt
            text
            :ripple="false"
            color="white"
            :class="{'active-item': $route.path === item.url}"
            class="nav font-weight-bold white--text"
          >
            {{ item.text }}
          </v-btn>
        </template>
      </v-toolbar-items>

      <div class="d-md-none">
        <mobile-drawer/>
      </div>

      <v-btn v-if="$store.state.auth.loggedIn" color="accent" rounded nuxt class="d-none d-md-block px-2 px-md-6" @click="logOutAdmin">
        Uitloggen
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import MobileDrawer from './AdminMobileNav';
import admin from '~/plugins/mixins/admin';
import account from '~/plugins/mixins/account';
import Logo from '~/components/Logo';

export default {
  components: {
    MobileDrawer,
    Logo,
  },
  mixins: [admin, account],
  props: {
    head: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: [
      {
        text: 'Offertes',
        url: '/admin/offertes',
      },
      {
        text: 'Facturen',
        url: '/admin/facturen',
      },
      {
        text: 'Relaties',
        url: '/admin/relaties',
      },
      {
        text: 'Abonnementen',
        url: '/admin/abonnementen',
      },
      {
        text: 'Pakketten',
        url: '/admin/pakketten',
      },
    ],
  }),
};
</script>
