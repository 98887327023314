const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['fixed-auth'] = require('../middleware/fixed-auth.js')
middleware['fixed-auth'] = middleware['fixed-auth'].default || middleware['fixed-auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['pages'] = require('../middleware/pages.js')
middleware['pages'] = middleware['pages'].default || middleware['pages']

middleware['theme'] = require('../middleware/theme.js')
middleware['theme'] = middleware['theme'].default || middleware['theme']

export default middleware
