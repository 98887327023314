<template>
  <nuxt-link :to="link">
    <v-img :src="imageUrl" :alt="$theme.current" class="logo" contain/>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: '/',
    },
  },
  computed: {
    imageUrl() {
      switch (this.$theme.current) {
        case 'zuidvallei':
          return require('~/assets/img/logo-zuid-vallei.png');
        case 'textaid':
          return require('~/assets/img/logo-text-aid.png');
        case 'claroread':
          return require('~/assets/img/logo-claroread.png');
        default:
          return require('~/assets/img/logo.png');
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.logo
  height: 42px
  width: 220px
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    width: 270px
  @media screen and (min-width: map-get($grid-breakpoints, 'xl'))
    width: 328px
</style>
