export default {
  methods: {
    gtmPushViews(products) {
      const impressions = [];

      products.forEach((product, index) => {
        impressions.push({
          name: product.title,
          category: product.category ? product.category[0] : null,
          position: index,
        });
      });

      this.$gtm.push({
        event: 'gtm.dom',
        ecommerce: {
          currencyCode: 'EUR',
          impressions: impressions,
        },
      });
    },

    gtmPushClick(product) {
      this.$gtm.push({
        event: 'productClick',
        ecommerce: {
          click: {
            products: [{
              name: product.title,
              category: product.category ? product.category[0] : null,
              price: product.price_incl,
            }],
          },
        },
      });
    },

    gtmPushAddToCart(product) {
      this.$gtm.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [{
              name: product.title,
              price: product.price_incl,
              quantity: product.quantity,
            }],
          },
        },
      });
    },

    gtmPushRemoveFromCart(product) {
      this.$gtm.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [{
              name: product.title,
              price: product.price_incl,
              quantity: product.quantity,
            }],
          },
        },
      });
    },

    gtmPushCheckout(rawProducts) {
      const products = [];

      rawProducts.forEach((product) => {
        products.push({
          name: product.title,
          price: product.price_incl,
          quantity: product.quantity,
        });
      });

      this.$gtm.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            products: products,
          },
        },
      });
    },
  },
};
