<template>
  <div>
    <v-row>
      <v-col :class="{'px-8': inSidebar}">
        <shopping-cart-product
          v-for="product in productsInCart"
          :key="product.key"
          :product="product"
          :hide-image="!inSidebar"
        />
        <p v-if="productsInCart.length === 0">Nog geen producten gekozen.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="secondary lighten-2 mt-6 py-12 px-10">
        <v-row>
          <v-col class="text-right">
            <div class="subtitle-1">Subtotaal</div>
            <div v-if="hasPhysicalItemInCart">Verzendkosten</div>
            <div class="pb-3">Waarvan btw</div>
            <div class="display-2">Eindtotaal</div>
          </v-col>
          <v-col class="shrink text-right">
            <div class="subtitle-1">€{{ subTotalIncludingTax | currency }}</div>
            <div v-if="hasPhysicalItemInCart">€{{ shippingCostIncludingVat | currency }}</div>
            <div class="pb-3">€{{ tax | currency }}</div>
            <div class="display-2">€{{ totalIncludingTax | currency }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cart from '~/plugins/mixins/cart';
import ShoppingCartProduct from '~/components/cart/ShoppingCartProduct';

export default {
  components: {
    ShoppingCartProduct,
  },
  mixins: [cart],
  props: {
    inSidebar: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
