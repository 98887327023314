export const CONCEPT = 'Concept';
export const SENT = 'Verzonden';
export const AGREED = 'Akkoord';
export const REJECTED = 'Afgewezen';
export const ACTIVE = 'Actief';
export const PAUSED = 'Gepauzeerd';
export const CANCELED = 'Gestopt';
export const TERMINATED = 'Opgezegd';
export const PENDING = 'In afwachting';
export const SUCCEEDED = 'Afgerond';
export const FAILED = 'Afgebroken';

// Stripe payments
export const DRAFT = {
  value: 'draft',
  text: 'Concept',
  enabled: () => false,
};
export const OPEN = {
  value: 'open',
  text: 'Open',
  enabled: () => false,
};
export const PENDING_PAYMENT = {
  value: 'pending_payment',
  text: 'Betaling onderweg',
  enabled: () => false,
};
export const PROCESSED = {
  value: 'processed',
  text: 'Verwerkt',
  enabled: () => false,
};
export const PAID = {
  value: 'paid',
  text: 'Betaald',
  enabled: status => [OPEN.value, PROCESSED.value, PENDING_PAYMENT.value].includes(status),
};
export const PAYMENT_CANCELED = {
  value: 'canceled',
  text: 'Geannuleerd',
  enabled: status => [OPEN.value, PENDING_PAYMENT.value].includes(status),
};
export const EXPIRED = {
  value: 'expired',
  text: 'Vervallen',
  enabled: () => false,
};
export const REFUNDED = {
  value: 'refunded',
  text: 'Teruggestort',
  enabled: status => status === PAID.value,
};

export const NEW = {
  value: 'Nieuw',
  text: 'Nieuw',
  enabled: () => false,
};

export const PENDING_REACTION = {
  value: 'In afwachting',
  text: 'In afwachting',
  enabled: status => status === NEW.value,
};

export const WON = {
  value: 'Gewonnen',
  text: 'Gewonnen',
  enabled: status => status === PENDING_REACTION.value,
};

export const LOST = {
  value: 'Verloren',
  text: 'Verloren',
  enabled: status => status === PENDING_REACTION.value,
};

export const PAYMENT_STATUSES = [
  DRAFT,
  OPEN,
  PENDING_PAYMENT,
  PROCESSED,
  PAID,
  PAYMENT_CANCELED,
  EXPIRED,
  REFUNDED,
];

export const QUOTATION_STATUSES = [
  NEW,
  PENDING_REACTION,
  WON,
  LOST,
];

export const PAYMENT_MANUAL = 0;
export const PAYMENT_IDEAL = 1;

export const ROLE_BEHEERDER = 5;
export const ROLE_DEVELOPER = 6;
export const ROLE_STUDENT = 7;

export const PHYSICAL_PRODUCT = 'Fysiek product';

// Relation types
export const ORGANISATIE = 'Organisatie';
export const COMPANY = 'Company';
export const PRIVATE = 'Private';
export const SCHOOL = 'School';
export const PARTICULIER = 'Particulier';
export const STUDENT = 'Student';

export const SHIPPING_POST_NL = 'PostNL';
export const SHIPPING_PICKUP = 'Afhalen';

export const DEFAULT_COUNTRY = 'Nederland';
