export default ({$axios, store, app}) => {
  $axios.onError((error) => {
    if (error.response) {
      const message = error.response.data.error;

      switch (error.response.status) {
        case 401:
        case 403:
          store.commit('notify/error', message || 'U hebt niet de juiste rechten om deze actie uit te voeren.');

          app.$bugsnag.notify(error);

          break;

        case 404:
          // Ignore
          break;

        case 422:
          store.dispatch('validation/setErrors', error.response.data.errors);
          store.commit('notify/error', 'Niet alle velden zijn correct ingevuld.');

          break;

        case 435: // Do nothing (show Magento user instruction)
        case 428: // Do nothing (show OTP field)
        case 412: // Precondition failed
          break;

        default:
          store.commit('notify/error', message || 'Er is een fout opgetreden.');
      }
    }

    return Promise.reject(error);
  });

  $axios.onRequest(() => {
    $axios.setHeader('X-Host', process.host);
    $axios.setHeader('X-Theme', app.$theme.current);

    store.dispatch('validation/clearErrors');
  });
};
