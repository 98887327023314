<template>
  <v-app-bar
    :flat="$theme.isDefault"
    app
    dark
    :color="$theme.isDefault ? 'primary' : 'white'"
    height="100"
  >
    <v-container class="d-flex align-center justify-space-between justify-lg-space-around justify-xl-space-between">
      <logo/>
      <v-toolbar-items class="d-none d-lg-flex px-6">
        <template v-for="item in mainMenu">
          <v-btn
            :key="item.text"
            :to="item.url"
            nuxt
            :ripple="false"
            text
            :class="[
              {'active-item': $route.path === item.url},
              $theme.isDefault ? 'white--text' : 'primary--text',
            ]"
            class="nav font-weight-bold"
          >
            {{ item.text }}
          </v-btn>
        </template>
      </v-toolbar-items>

      <div class="d-lg-none">
        <mobile-drawer/>
      </div>

      <div class="d-none d-lg-flex search">
        <search-field v-if="$theme.isDefault"/>

        <v-btn fab nuxt color="accent" class="no-line mr-5" @click="$store.dispatch('cart/toggleCartDrawer')">
          <v-badge
            v-if="productsCount > 0"
            :content="productsCount"
            color="red"
          >
            <font-awesome-icon :icon="['far', 'shopping-cart']"/>
          </v-badge>
          <font-awesome-icon v-else :icon="['far', 'shopping-cart']"/>
        </v-btn>
        <v-btn v-if="$store.state.auth.loggedIn" color="accent" rounded to="/account" nuxt class="px-6">
          Account
        </v-btn>
        <v-btn
          v-else-if="productSite && productSite.external_login_url"
          :href="productSite.external_login_url"
          target="_blank"
          rel="noopener"
          color="accent"
          rounded
          class="px-6"
        >
          Inloggen bij {{ productSite.meta_title }}
        </v-btn>
        <v-btn v-else color="accent" rounded :to="{name: 'inloggen'}" nuxt class="px-6">
          Inloggen webshop
        </v-btn>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex';
import MobileDrawer from './MobileDrawer';
import SearchField from '~/components/search/SearchField';
import Logo from '~/components/Logo';

export default {
  components: {
    MobileDrawer,
    SearchField,
    Logo,
  },
  props: {
    head: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      mainMenu: state => state.mainMenu,
      productsCount: state => state.cart.products.length,
      productSite: state => state.productSite.productSite,
    }),
  },
};
</script>

<style lang="sass" scoped>
.search
  position: relative
</style>
