<template>
  <div
    :class="[
      {'white-border pt-10': dark},
      {'heading-text font-weight-bold primary--text text--lighten-2': !$theme.isDefault}
    ]"
    class="w-content"
  >
    <h2
      v-if="$theme.isDefault" :class="!dark ? 'primary--text text--lighten-2' : 'white--text'"
      class="w-content heading-text font-weight-bold pb-2"
    >
      <slot/>
    </h2>
    <slot v-else/>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>

div:not(.white-border):not(.no-border)
  border-bottom: 7px solid
  border-top: 0
  border-image: url('~assets/img/border-heading.svg') 6
  border-image-repeat: repeat

.white-border > h2
  border-bottom: 7px solid
  border-top: 0
  border-image: url('~assets/img/border-heading-wit.svg') 6
  border-image-repeat: repeat

</style>
