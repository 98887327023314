export const state = () => ({
  cartDrawer: false,
  shipping: true,
  products: [],
  country: null, // see mixins/cart
});

export const actions = {
  toggleCartDrawer({commit, state}, payload = null) {
    if (payload) {
      commit('SET_CART_DRAWER', payload);
    } else {
      commit('SET_CART_DRAWER', !state.cartDrawer);
    }
  },
  clearCart({commit}) {
    commit('CLEAR_CART');
  },
  setCountry({commit}, payload) {
    commit('SET_COUNTRY', payload);
  },
};

export const getters = {
  shipping(state) {
    return state.shipping;
  },
  hasItemsInCart(state) {
    return state.products.length !== 0;
  },
  hasSubscriptionItemInCart(state) {
    return state.products.filter(product => product.is_subscription).length !== 0;
  },
};

export const mutations = {
  SET_CART_DRAWER(state, payload) {
    state.cartDrawer = payload;
  },
  CLEAR_CART(state) {
    state.products = [];
  },
  REMOVE_PRODUCT(state, payload) {
    state.products = state.products.filter(product => product !== payload);
  },
  ADD_PRODUCT_TO_CART(state, payload) {
    const productIndex = state.products.map(product => product.uniqid).indexOf(payload.uniqid);

    if (productIndex !== -1) {
      state.products[productIndex].quantity += payload.quantity;
      state.products[productIndex].product_total += payload.product_total;
    } else {
      state.products.push(payload);
    }
  },
  SET_SHIPPING(state, shipping) {
    state.shipping = shipping;
  },
  SET_COUNTRY(state, country) {
    state.country = country;
  },
};
