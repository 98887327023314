<template>
  <v-row align="stretch" no-gutters>
    <v-col cols="12" :md="small ? 4 : 6" v-bind="!reverse ? {'offset-md': 1, 'order-md': 'last'} : {}">
      <v-img
        :src="imageUrl"
        alt=""
        height="100%"
        position="center center"
        aria-hidden="true"
      />
    </v-col>
    <v-col cols="12" :md="small ? 6 : 4" offset-md="1" class="d-flex flex-column justify-center px-3 pt-7 pb-12 px-md-0 py-md-12">
      <border-header>
        {{ title }}
      </border-header>
      <dynamic-text class="py-5 pl-5 pl-md-7" :html="text"/>
      <v-btn
        v-if="button"
        :to="!isExternalLink ? buttonLink : undefined"
        :href="isExternalLink ? buttonLink : undefined"
        :nuxt="!isExternalLink"
        rounded
        color="accent"
        class="w-content ml-7"
      >
        {{ button }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import BorderHeader from '~/components/header/BorderHeader';

export default {
  components: {
    BorderHeader,
  },
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    button: {
      type: String,
      default: null,
    },
    buttonLink: {
      type: String,
      default: '/',
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.buttonLink === 'string' && this.buttonLink[0] !== '/';
    },
  },
};
</script>
