import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faArrowLeft,
  faArrowRight,
  faBan,
  faCar,
  faCaretDown,
  faCaretUp,
  faCircle,
  faDotCircle,
  faDownload,
  faExternalLinkAlt,
  faFileDownload,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSquare,
  faShoppingCart,
  faTimes,
  faVolume,
  faWalking,
  faTrash,
  faTrashAlt,
  faQuestionCircle,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCheckSquare,
  faPaperclip,
  faTimesCircle,
  faChevronLeft,
  faChevronDoubleLeft,
  faChevronRight,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faEdit,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faInstagram,
  faFacebookF,
  faTelegramPlane,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowLeft,
  faArrowRight,
  faBan,
  faCar,
  faCaretDown,
  faCaretUp,
  faCheckSquare,
  faCircle,
  faDotCircle,
  faDownload,
  faExternalLinkAlt,
  faInstagram,
  faFacebookF,
  faFileDownload,
  faSearch,
  faShoppingCart,
  faSquare,
  faTelegramPlane,
  faTimes,
  faTwitter,
  faVolume,
  faWalking,
  faYoutube,
  faSort,
  faSortDown,
  faTrash,
  faTrashAlt,
  faPaperclip,
  faTimesCircle,
  faSortUp,
  faEllipsisH,
  faEllipsisV,
  faChevronLeft,
  faChevronDoubleLeft,
  faChevronRight,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faEnvelope,
  faEdit,
  faInfoCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
