import Vue from 'vue';
import * as moment from 'moment';

const countDecimals = function (value) {
  if (!value || Math.floor(value) === value) {
    return 0;
  }

  const parts = value.toString().split('.');
  if (!parts[1]) {
    return 0;
  }

  return parts[1].length || 0;
};

Vue.filter('currency', val =>
  parseFloat(val)
    .toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
);

Vue.filter('decimal', (val, decimals) => {
  decimals = decimals || countDecimals(val);

  if (decimals === 0) {
    return val;
  }

  return parseFloat(val).toFixed(decimals).replace('.', ',');
});

Vue.filter('ucFirst', val =>
  val.charAt(0).toUpperCase() + val.slice(1),
);

Vue.filter('moment', (val, format) =>
  moment(val).format(format),
);

Vue.filter('weight', (val) => {
  if (val > 1000) {
    return (val / 1000) + ' kg';
  }
  return val + ' g';
});
