<template>
  <v-snackbar v-model="active" v-bind="snackbar.props" top>
    <v-row no-gutters align="center">
      <v-col class="subtitle-2 font-weight-bold">
        {{ snackbar.message }}
        <v-btn v-if="snackbar.props.refresh" @click.native="snackbar.props.refresh">Vernieuwen</v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn icon @click="active = false">
          <font-awesome-icon :icon="['far', 'times']" fixed-width size="lg"/>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      snackbar: state => state.notify.snackbar,
    }),
    active: {
      set(isActive) {
        this.$store.commit('notify/show', isActive);
      },
      get() {
        return this.snackbar.active;
      },
    },
  },
};
</script>
