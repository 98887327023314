<template>
  <v-dialog
    v-model="dialog"
    dark
    fullscreen
    :color="$theme.isDefault ? 'primary' : 'white'"
  >
    <template v-slot:activator="{on}">
      <v-btn fab nuxt color="accent" class="d-none d-sm-inline-flex no-line mr-5" :to="{name: 'winkelwagen'}">
        <v-badge
          v-if="productsCount > 0"
          :content="productsCount"
          color="red"
        >
          <font-awesome-icon :icon="['far', 'shopping-cart']"/>
        </v-badge>
        <font-awesome-icon v-else :icon="['far', 'shopping-cart']"/>
      </v-btn>
      <v-btn fab color="accent" class="body-2 no-line" v-on="on">
        {{ dialog ? 'Sluit' : 'Menu' }}
      </v-btn>
    </template>
    <v-container :class="$theme.isDefault ? 'primary' : 'white'" class="fill-height align-start">
      <v-row class="text-center" no-gutters>
        <v-col>
          <v-row>
            <v-col class="d-flex justify-center mb-0 mt-sm-4 mb-sm-10">
              <search-field/>
              <v-btn
                v-if="$breakpoint.is.xsOnly"
                fab
                large
                color="accent"
                class="no-line"
                :to="{name: 'winkelwagen'}"
              >
                <v-badge
                  v-if="productsCount > 0"
                  color="red"
                  :content="productsCount"
                >
                  <font-awesome-icon :icon="['far', 'shopping-cart']" size="lg"/>
                </v-badge>
                <font-awesome-icon v-else :icon="['far', 'shopping-cart']" size="lg"/>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" :class="$theme.isDefault ? 'primary' : 'white'" class="fill-height flex-column mb-12 mx-n3" no-gutters>
            <v-list v-for="item in mainMenu" :key="item.text" :color="$theme.isDefault ? 'primary' : 'white'">
              <v-list-item :to="item.url" nuxt :class="{'active-item': $route.path === item.url}" :ripple="false" class="headline font-weight-bold justify-center pb-4">
                <span :class="{'primary--text': !$theme.isDefault}">{{ item.text }}</span>
              </v-list-item>
            </v-list>
            <v-col class="text-center mt-6">
              <v-btn v-if="$store.state.auth.loggedIn" color="accent" rounded to="/account" width="120" nuxt>
                Account
              </v-btn>
              <v-btn v-if="$store.state.auth.loggedIn" color="accent" rounded nuxt class="ml-5" @click="logOutUser">
                Uitloggen
              </v-btn>
              <v-btn v-else color="accent" rounded :to="{name: 'inloggen'}" nuxt>
                Inloggen webshop
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import SearchField from '~/components/search/SearchField';
import account from '~/plugins/mixins/account';

export default {
  components: {
    SearchField,
  },
  mixins: [account],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      mainMenu: state => state.mainMenu,
      productsCount: state => state.cart.products.length,
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.dialog = false;
      },
    },
  },
};

</script>

<style lang="sass" scoped>
::v-deep .v-dialog--fullscreen
  margin: 100px 0 0 0
</style>
