<template>
  <v-app>
    <cart-drawer/>
    <drawer/>
    <v-main class="top">
      <nuxt/>
    </v-main>
    <page-footer/>
  </v-app>
</template>

<script>
import Drawer from './partials/Drawer';
import CartDrawer from './partials/CartDrawer';
import PageFooter from './partials/PageFooter';
import dynamicFavicon from '~/plugins/mixins/dynamic-favicon';

export default {
  components: {
    Drawer,
    PageFooter,
    CartDrawer,
  },
  mixins: [dynamicFavicon],
};
</script>
