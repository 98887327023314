<template>
  <client-only>
    <div
      v-if="visible && url"
      :id="`readspeaker_button${uniqId}`"
      class="rs_skip rsbtn rs_preserve read__btn caption primary--text no-line"
      :class="{'placement': explanation}"
    >
      <a
        rel="nofollow noopener"
        class="rsbtn_play"
        accesskey="L"
        title="Laat de tekst voorlezen met ReadSpeaker"
        :href="url"
        @click.prevent
      >
        <span class="rsbtn_left rsimg rspart">
          <span class="rsbtn_text">
            <span
              :class="{'d-none d-md-inline-flex': !explanation, 'accent--text': lighten}"
            >Lees voor</span>
            <v-btn fab color="accent" :ripple="false" :class="explanation ? 'ml-4' : 'ml-2'">
              <font-awesome-icon :icon="['far', 'volume']" size="lg"/>
            </v-btn>
          </span>
        </span>
      </a>
    </div>
  </client-only>
</template>

<script>
export default {
  props: {
    explanation: {
      type: Boolean,
      default: false,
    },
    readId: {
      type: String,
      default: 'read',
    },
  },
  data: () => ({
    visible: true,
    uniqId: +new Date(),
    currentUrl: '',
  }),
  computed: {
    url() {
      if (process.server) {
        return '';
      }

      return `https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=4469&lang=nl_nl&readid=${this.readId}&url=${encodeURI(this.currentUrl)}`;
    },
    lighten() {
      return this.$route.name === 'shop' ||
        this.$route.name === 'shop-category' ||
        this.$route.name === 'shop-category-product';
    },
  },
  watch: {
    $route() {
      if (process.client) {
        this.$nextTick(() => {
          this.updateUrl();
        });
      }
    },
    url() {
      this.$nextTick(() => {
        // Force reload component
        this.visible = false;
        this.uniqId = +new Date();

        this.reload();
      });
    },
  },
  mounted() {
    this.updateUrl();
  },
  methods: {
    updateUrl() {
      if (this.$root.context.isDev) {
        this.currentUrl = 'https://woordhelder.nl' + window.location.pathname; // for debugging
      } else {
        this.currentUrl = window.location.href;
      }
    },
    reload() {
      if (process.client && window.ReadSpeaker) {
        this.visible = true;

        this.$nextTick(() => {
          try {
            if (window.ReadSpeaker) {
              if (window.ReadSpeaker?.ui) {
                if (window.ReadSpeaker.PlayerAPI?.stop) {
                  window.ReadSpeaker.PlayerAPI.stop();
                }

                window.ReadSpeaker.ui.addClickEvents();
              } else if (window.ReadSpeaker.init) {
                window.ReadSpeaker.init();
              } else {
                console.log(window.ReadSpeaker);
              }
            }
          } catch (e) {
            console.error('ReadSpeaker error');
            console.error(e);
          }
        });
      }
    },
  },
  head() {
    return {
      script: [{
        hid: 'pre-rs',
        innerHTML: 'window.rsConf = {general: {usePost: true}};',
      }, {
        hid: 'rs',
        src: 'https://cdn1.readspeaker.com/script/4469/webReader/webReader.js?pids=wr',
      }],
    };
  },
};
</script>

<style lang="sass" scoped>
.rsbtn
  position: fixed
  z-index: 5
  right: 28px
  bottom: 50px

  @media screen and (min-width: map-get($grid-breakpoints, 'md'))
    top: 150px
    right: 50px
    bottom: auto

.rsbtn_play
  background: none
  border: 0
  padding: 0 !important

  &:hover
    background: none

  .rsbtn_left
    margin: 0

    .rsbtn_text::before
      content: none

    .rsbtn_text span:not(.accent--text)
      color: var(--v-primary-base) !important

  .rsimg
    display: flex
    align-items: center

::v-deep .rsbtn_tooltoggle
  display: none !important

::v-deep .rsbtn_exp
  position: fixed
  top: 0
  left: 50%
  transform: translateX(-50%)

  .rs-player-loadindicator
    display: none !important

::v-deep .v-btn--round
  border-radius: 50%

::v-deep .v-btn__content
  padding: 0 !important
  height: auto !important
  font-size: 15px !important
</style>
