<template>
  <v-navigation-drawer
    v-model="cartDrawer"
    width="40vw"
    app
    temporary
    right
  >
    <v-row class="flex-column mx-0">
      <v-col :class="$theme.isDefault ? 'primary underline' : 'secondary lighten-2'">
        <v-row align="center" class="ml-8 mr-5" no-gutters>
          <v-col tag="h2" :class="$theme.isDefault ? 'primary--text text--lighten-6' : 'primary--text text--lighten-2'" class="grow">
            Winkelwagen
          </v-col>
          <v-col class="shrink">
            <v-btn fab color="accent" @click.stop="setCartDrawer(false)">
              <font-awesome-icon :icon="['far', 'times']"/>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <cart
      style="height: calc(100vh - 203px); overflow: auto; overflow-x: hidden;"
    />

    <v-row class="px-6 pt-2 pb-20" align="center">
      <v-col>
        <v-btn text to="/shop" nuxt @click.stop="setCartDrawer(false)">
          Naar de winkel
        </v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn rounded color="accent" :to="{name: 'afrekenen'}" :disabled="productsInCart.length < 1">
          Afrekenen
        </v-btn>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import cartMixin from '~/plugins/mixins/cart';
import Cart from '~/components/cart/Cart';

export default {
  components: {
    Cart,
  },
  mixins: [cartMixin],
  computed: {
    cartDrawer: {
      get() {
        return this.$store.state.cart.cartDrawer;
      },
      set(val) {
        this.setCartDrawer(val);
      },
    },
  },
  methods: {
    setCartDrawer(val) {
      if (this.cartDrawer !== val) {
        this.$store.dispatch('cart/toggleCartDrawer', val);
      }
    },
  },
};
</script>
