import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _70695fce = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _22fd8904 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _13bb965e = () => interopDefault(import('../pages/afrekenen.vue' /* webpackChunkName: "pages/afrekenen" */))
const _d1b4b5a6 = () => interopDefault(import('../pages/betaling/index.vue' /* webpackChunkName: "pages/betaling/index" */))
const _4776ffb4 = () => interopDefault(import('../pages/brochure-aanvragen.vue' /* webpackChunkName: "pages/brochure-aanvragen" */))
const _3765a60f = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _34c4edb5 = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _6f24a347 = () => interopDefault(import('../pages/kennisbank/index.vue' /* webpackChunkName: "pages/kennisbank/index" */))
const _0eff3aea = () => interopDefault(import('../pages/nieuws.vue' /* webpackChunkName: "pages/nieuws" */))
const _f004d664 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _e157eac8 = () => interopDefault(import('../pages/nieuws/_category/index.vue' /* webpackChunkName: "pages/nieuws/_category/index" */))
const _b59a6182 = () => interopDefault(import('../pages/nieuws/_category/_item/index.vue' /* webpackChunkName: "pages/nieuws/_category/_item/index" */))
const _1e31e418 = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _09e25753 = () => interopDefault(import('../pages/over/_slug.vue' /* webpackChunkName: "pages/over/_slug" */))
const _46af5bbf = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _2299b0c7 = () => interopDefault(import('../pages/producten/index.vue' /* webpackChunkName: "pages/producten/index" */))
const _64a98d3e = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _d928d78a = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _2bd8bd8c = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _a9e65786 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _42ca0b4b = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _7159a472 = () => interopDefault(import('../pages/shop/_category/_product.vue' /* webpackChunkName: "pages/shop/_category/_product" */))
const _03b3e048 = () => interopDefault(import('../pages/speciaal-voor-scholen.vue' /* webpackChunkName: "pages/speciaal-voor-scholen" */))
const _603a660a = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _440cb8d8 = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _7b81db7c = () => interopDefault(import('../pages/winkelwagen.vue' /* webpackChunkName: "pages/winkelwagen" */))
const _2316f250 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _39c8cc76 = () => interopDefault(import('../pages/admin/abonnementen/index.vue' /* webpackChunkName: "pages/admin/abonnementen/index" */))
const _60410679 = () => interopDefault(import('../pages/admin/facturen/index.vue' /* webpackChunkName: "pages/admin/facturen/index" */))
const _5d71dd4d = () => interopDefault(import('../pages/admin/offertes/index.vue' /* webpackChunkName: "pages/admin/offertes/index" */))
const _4843a86e = () => interopDefault(import('../pages/admin/pakketten.vue' /* webpackChunkName: "pages/admin/pakketten" */))
const _7ed508dc = () => interopDefault(import('../pages/admin/relaties/index.vue' /* webpackChunkName: "pages/admin/relaties/index" */))
const _0e7ab4eb = () => interopDefault(import('../pages/betaling/afgerond.vue' /* webpackChunkName: "pages/betaling/afgerond" */))
const _92ef3526 = () => interopDefault(import('../pages/demo/rekenblobs.vue' /* webpackChunkName: "pages/demo/rekenblobs" */))
const _0410ec36 = () => interopDefault(import('../pages/demo/taalblobs.vue' /* webpackChunkName: "pages/demo/taalblobs" */))
const _fc920c64 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _9b385fe6 = () => interopDefault(import('../pages/info/privacy-statement.vue' /* webpackChunkName: "pages/info/privacy-statement" */))
const _0ee2c203 = () => interopDefault(import('../pages/school/leerling.vue' /* webpackChunkName: "pages/school/leerling" */))
const _91e6904a = () => interopDefault(import('../pages/admin/facturen/aanmaken.vue' /* webpackChunkName: "pages/admin/facturen/aanmaken" */))
const _64f6fef2 = () => interopDefault(import('../pages/admin/offertes/aanmaken.vue' /* webpackChunkName: "pages/admin/offertes/aanmaken" */))
const _8918ffbc = () => interopDefault(import('../pages/admin/relaties/aanmaken.vue' /* webpackChunkName: "pages/admin/relaties/aanmaken" */))
const _a4416d26 = () => interopDefault(import('../pages/admin/abonnementen/_id.vue' /* webpackChunkName: "pages/admin/abonnementen/_id" */))
const _6afe6216 = () => interopDefault(import('../pages/admin/offertes/_id.vue' /* webpackChunkName: "pages/admin/offertes/_id" */))
const _c71f2550 = () => interopDefault(import('../pages/admin/organisaties/_id.vue' /* webpackChunkName: "pages/admin/organisaties/_id" */))
const _67510132 = () => interopDefault(import('../pages/admin/particulieren/_id.vue' /* webpackChunkName: "pages/admin/particulieren/_id" */))
const _2a79b7b5 = () => interopDefault(import('../pages/admin/scholen/_id.vue' /* webpackChunkName: "pages/admin/scholen/_id" */))
const _e06b7d0a = () => interopDefault(import('../pages/admin/studenten/_id.vue' /* webpackChunkName: "pages/admin/studenten/_id" */))
const _63d51ae8 = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _35af04c7 = () => interopDefault(import('../pages/contact/_slug.vue' /* webpackChunkName: "pages/contact/_slug" */))
const _6d6e01ff = () => interopDefault(import('../pages/kennisbank/_slug.vue' /* webpackChunkName: "pages/kennisbank/_slug" */))
const _4a014862 = () => interopDefault(import('../pages/kennisbank/_slug/_item.vue' /* webpackChunkName: "pages/kennisbank/_slug/_item" */))
const _20e30f7f = () => interopDefault(import('../pages/producten/_slug.vue' /* webpackChunkName: "pages/producten/_slug" */))
const _1552d62c = () => interopDefault(import('../pages/verleng/_id.vue' /* webpackChunkName: "pages/verleng/_id" */))
const _6ff5d0f6 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _af790d84 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b0bd22d4 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _70695fce,
    name: "account"
  }, {
    path: "/admin",
    component: _22fd8904,
    name: "admin"
  }, {
    path: "/afrekenen",
    component: _13bb965e,
    name: "afrekenen"
  }, {
    path: "/betaling",
    component: _d1b4b5a6,
    name: "betaling"
  }, {
    path: "/brochure-aanvragen",
    component: _4776ffb4,
    name: "brochure-aanvragen"
  }, {
    path: "/contact",
    component: _3765a60f,
    name: "contact"
  }, {
    path: "/inloggen",
    component: _34c4edb5,
    name: "inloggen"
  }, {
    path: "/kennisbank",
    component: _6f24a347,
    name: "kennisbank"
  }, {
    path: "/nieuws",
    component: _0eff3aea,
    children: [{
      path: "",
      component: _f004d664,
      name: "nieuws"
    }, {
      path: ":category",
      component: _e157eac8,
      name: "nieuws-category"
    }, {
      path: ":category/:item",
      component: _b59a6182,
      name: "nieuws-category-item"
    }]
  }, {
    path: "/over",
    component: _1e31e418,
    name: "over",
    children: [{
      path: ":slug?",
      component: _09e25753,
      name: "over-slug"
    }]
  }, {
    path: "/over-ons",
    component: _46af5bbf,
    name: "over-ons"
  }, {
    path: "/producten",
    component: _2299b0c7,
    name: "producten"
  }, {
    path: "/registreren",
    component: _64a98d3e,
    name: "registreren"
  }, {
    path: "/school",
    component: _d928d78a,
    name: "school"
  }, {
    path: "/shop",
    component: _2bd8bd8c,
    children: [{
      path: "",
      component: _a9e65786,
      name: "shop"
    }, {
      path: ":category",
      component: _42ca0b4b,
      name: "shop-category"
    }, {
      path: ":category/:product",
      component: _7159a472,
      name: "shop-category-product"
    }]
  }, {
    path: "/speciaal-voor-scholen",
    component: _03b3e048,
    name: "speciaal-voor-scholen"
  }, {
    path: "/sso",
    component: _603a660a,
    name: "sso"
  }, {
    path: "/wachtwoord-vergeten",
    component: _440cb8d8,
    name: "wachtwoord-vergeten"
  }, {
    path: "/winkelwagen",
    component: _7b81db7c,
    name: "winkelwagen"
  }, {
    path: "/zoekresultaten",
    component: _2316f250,
    name: "zoekresultaten"
  }, {
    path: "/admin/abonnementen",
    component: _39c8cc76,
    name: "admin-abonnementen"
  }, {
    path: "/admin/facturen",
    component: _60410679,
    name: "admin-facturen"
  }, {
    path: "/admin/offertes",
    component: _5d71dd4d,
    name: "admin-offertes"
  }, {
    path: "/admin/pakketten",
    component: _4843a86e,
    name: "admin-pakketten"
  }, {
    path: "/admin/relaties",
    component: _7ed508dc,
    name: "admin-relaties"
  }, {
    path: "/betaling/afgerond",
    component: _0e7ab4eb,
    name: "betaling-afgerond"
  }, {
    path: "/demo/rekenblobs",
    component: _92ef3526,
    name: "demo-rekenblobs"
  }, {
    path: "/demo/taalblobs",
    component: _0410ec36,
    name: "demo-taalblobs"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _fc920c64,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/privacy-statement",
    component: _9b385fe6,
    name: "info-privacy-statement"
  }, {
    path: "/school/leerling",
    component: _0ee2c203,
    name: "school-leerling"
  }, {
    path: "/admin/facturen/aanmaken",
    component: _91e6904a,
    name: "admin-facturen-aanmaken"
  }, {
    path: "/admin/offertes/aanmaken",
    component: _64f6fef2,
    name: "admin-offertes-aanmaken"
  }, {
    path: "/admin/relaties/aanmaken",
    component: _8918ffbc,
    name: "admin-relaties-aanmaken"
  }, {
    path: "/admin/abonnementen/:id",
    component: _a4416d26,
    name: "admin-abonnementen-id"
  }, {
    path: "/admin/offertes/:id",
    component: _6afe6216,
    name: "admin-offertes-id"
  }, {
    path: "/admin/organisaties/:id?",
    component: _c71f2550,
    name: "admin-organisaties-id"
  }, {
    path: "/admin/particulieren/:id?",
    component: _67510132,
    name: "admin-particulieren-id"
  }, {
    path: "/admin/scholen/:id?",
    component: _2a79b7b5,
    name: "admin-scholen-id"
  }, {
    path: "/admin/studenten/:id?",
    component: _e06b7d0a,
    name: "admin-studenten-id"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _63d51ae8,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/contact/:slug",
    component: _35af04c7,
    name: "contact-slug"
  }, {
    path: "/kennisbank/:slug",
    component: _6d6e01ff,
    name: "kennisbank-slug",
    children: [{
      path: ":item?",
      component: _4a014862,
      name: "kennisbank-slug-item"
    }]
  }, {
    path: "/producten/:slug",
    component: _20e30f7f,
    name: "producten-slug"
  }, {
    path: "/verleng/:id?",
    component: _1552d62c,
    name: "verleng-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _6ff5d0f6,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _af790d84,
    name: "index"
  }, {
    path: "/*",
    component: _b0bd22d4,
    name: "*"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
