import {ROLE_BEHEERDER, ROLE_DEVELOPER} from '~/plugins/constants';

export default ({store, redirect}) => {
  if (!store.state.auth.loggedIn) {
    return redirect('/admin');
  }
  if (store.state.auth.user.role_id !== ROLE_BEHEERDER && store.state.auth.user.role_id !== ROLE_DEVELOPER) {
    return redirect('/');
  }
};
