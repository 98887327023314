export const state = () => ({
  items: [],
  page: 1,
  perPage: null,
  total: null,
  quotation: {},
  stats: {
    agreedAmount: null,
    denied: null,
    outstanding: null,
  },
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_PER_PAGE(state, limit) {
    state.perPage = limit;
  },
  SET_STATS(state, payload) {
    state.stats.agreedAmount = payload.agreed ? parseFloat(payload.agreed).toFixed(2) : 0;
    state.stats.denied = payload.denied;
    if (payload.outstanding) {
      state.stats.outstanding = payload.outstanding;
    }
  },
  SET_QUOTATION(state, payload) {
    state.quotation = payload;
  },
};

export const actions = {
  async getPage({state, commit}, options = {}) {
    options = {
      page: state.page,
      ...options,
    };

    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/admin/quotations?${params}`;

    try {
      const {data} = await this.$axios.get(url);

      commit('SET_ITEMS', data.data);
      commit('SET_TOTAL', parseInt(data.meta.total, 10));
      commit('SET_PER_PAGE', parseInt(data.meta.per_page, 10));
    } catch (e) {
      console.error(e);
    }
  },
  async getSingle({state, commit}, id) {
    try {
      const {data} = await this.$axios.get(`/admin/quotations/${id}`);
      commit('SET_QUOTATION', data.data);
    } catch (e) {
      console.error(e);
    }
  },
  async getStats({commit}, options = {}) {
    const params = Object.entries(options).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    const url = `/admin/quotations/stats?${params}`;

    try {
      const {data} = await this.$axios.get(url);
      commit('SET_STATS', data);
    } catch (e) {
      console.error(e);
    }
  },
};
