<template>
  <v-app>
    <admin-nav-bar/>
    <snackbar/>
    <confirm-dialog ref="confirm"/>
    <v-main class="fill-height top">
      <nuxt/>
    </v-main>
  </v-app>
</template>

<script>
import AdminNavBar from './partials/AdminNavBar';
import Snackbar from './partials/Snackbar';
import ConfirmDialog from '~/components/dialogs/ConfirmDialog';
import dynamicFavicon from '~/plugins/mixins/dynamic-favicon';

export default {
  middleware: 'admin',
  components: {
    AdminNavBar,
    Snackbar,
    ConfirmDialog,
  },
  mixins: [dynamicFavicon],
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>
