import {mapState} from 'vuex';
import {DEFAULT_COUNTRY, PHYSICAL_PRODUCT} from '~/plugins/constants';

export default {
  computed: {
    ...mapState({
      productsInCart: state => state.cart.products,
      shipping: state => state.cart.shipping,
      countries: state => state.countries,
      country: state => state.cart.country || DEFAULT_COUNTRY,
    }),
    weight() {
      if (!this.hasPhysicalItemInCart) {
        return 0;
      }

      return this.productsInCart
        .filter(product => product.type === PHYSICAL_PRODUCT)
        .map((product) => {
          return product.quantity * product.weight;
        })
        .reduce((total, currentValue) => total + currentValue);
    },
    shippingVat() {
      let highestTaxGroup = 0;

      this.productsInCart.filter(product => product.type === PHYSICAL_PRODUCT).forEach((product) => {
        if (product.tax_group > highestTaxGroup) {
          highestTaxGroup = product.tax_group;
        }
      });

      return highestTaxGroup;
    },
    shippingCost() {
      if (!this.shipping || !this.hasPhysicalItemInCart) {
        return 0;
      }

      if (this.subTotal >= this.countries[this.country].free_shipping_threshold) {
        return 0;
      }

      if (this.weight <= this.countries[this.country].small_package_weight_limit) {
        return this.countries[this.country].small_package;
      }

      return this.countries[this.country].large_package;
    },
    shippingCostIncludingVat() {
      return this.shippingCost * (1 + (this.shippingVat / 100));
    },
    hasPhysicalItemInCart() {
      return this.productsInCart.filter(product => product.type === PHYSICAL_PRODUCT).length !== 0;
    },
    subTotal() {
      let total = 0;

      this.productsInCart.forEach((product) => {
        total += product.price_excl * product.quantity;

        product.options.forEach((option) => {
          total += option.price_excl * product.quantity;
        });

        if (product.training) {
          total += product.training.price_excl;
        }
      });

      return total;
    },
    subTotalIncludingTax() {
      let total = 0;

      this.productsInCart.forEach((product) => {
        total += product.price_incl * product.quantity;

        product.options.forEach((option) => {
          total += option.price_incl * product.quantity;
        });

        if (product.training) {
          total += product.training.price_incl;
        }
      });

      return total;
    },
    totalIncludingTax() {
      let total = 0;

      this.productsInCart.forEach((product) => {
        total += product.quantity * product.price_incl;

        product.options.forEach((option) => {
          total += product.quantity * option.price_incl;
        });

        if (product.training) {
          total += product.training.price_incl;
        }
      });

      if (this.hasPhysicalItemInCart) {
        total += this.shippingCostIncludingVat;
      }

      return total;
    },
    tax() {
      return this.totalIncludingTax - this.subTotal - this.shippingCost;
    },
    totalInitialPayment() {
      let total = 0;

      this.productsInCart.forEach((product) => {
        if (product.training) {
          total += product.training.price_excl;
        }
      });

      if (this.hasPhysicalItemInCart) {
        total += this.shippingCostIncludingVat;
      }

      return this.totalSubscriptionPayment + total;
    },
    totalSubscriptionPayment() {
      let total = 0;

      this.productsInCart.forEach((product) => {
        total += product.quantity * product.price_excl * (1 + (product.tax_group / 100));

        product.options.forEach((option) => {
          total += product.quantity * option.price_excl * (1 + (option.tax_group / 100));
        });
      });

      return total;
    },
  },
  mounted() {
    // Init country only when it is still empty
    if (!this.$store.state.cart.country) {
      let country = DEFAULT_COUNTRY;

      if (this.$store.state.auth.loggedIn && this.$store.state.auth.user?.country) {
        country = this.$store.state.auth.user?.country;
      }

      this.$store.commit('cart/SET_COUNTRY', country);
    }
  },
};
