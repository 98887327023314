<template>
  <v-dialog
    v-model="dialog"
    dark
    fullscreen
    class="primary"
  >
    <template v-slot:activator="{ on }">
      <v-btn fab color="accent" class="body-2 no-line" v-on="on">
        {{ dialog ? 'Sluit' : 'Menu' }}
      </v-btn>
    </template>
    <v-container class="fill-height primary align-start pt-sm-28">
      <v-row class="text-center" no-gutters>
        <v-col>
          <v-row align="center" class="fill-height flex-column primary mb-12 mx-n3" no-gutters @click="dialog = false">
            <v-list v-for="item in menu" :key="item.text" color="primary">
              <v-list-item :to="item.url" nuxt :class="{'active-item': $route.path === item.url}" :ripple="false" class="headline font-weight-bold justify-center pb-4">
                <span>{{ item.text }}</span>
              </v-list-item>
            </v-list>
            <v-col class="text-center mt-6">
              <v-btn v-if="$store.state.auth.loggedIn" color="accent" rounded to="/admin" width="120" nuxt>
                Account
              </v-btn>
              <v-btn v-if="$store.state.auth.loggedIn" color="accent" rounded nuxt class="ml-5" @click="logOutAdmin">
                Uitloggen
              </v-btn>
              <v-btn v-else color="accent" rounded :to="{name: 'inloggen'}" nuxt>
                Inloggen
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import account from '~/plugins/mixins/account';

export default {
  mixins: [account],
  data() {
    return {
      dialog: false,
      menu: [
        {
          text: 'Offertes',
          url: '/admin/offertes',
        },
        {
          text: 'Facturen',
          url: '/admin/facturen',
        },
        {
          text: 'Relaties',
          url: '/admin/relaties',
        },
        {
          text: 'Abonnementen',
          url: '/admin/abonnementen',
        },
        {
          text: 'Pakketten',
          url: '/admin/pakketten',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      mainMenu: state => state.mainMenu,
      productsCount: state => state.cart.products.length,
    }),
  },
};

</script>

<style lang="sass" scoped>
::v-deep .v-dialog--fullscreen
  margin: 100px 0 0 0
</style>
